.profile-list-component {
    display: inline-table;
    user-select: none;
    position: relative;
}

.main-btn {
    box-shadow: 0px 0px 0px 0px white;
    margin: 0px;
    padding: 0px 4px 0px 0px;
    background: transparent;
    border: none;
    outline: none;
}

.main-btn-icon {
    padding: 0px 0px 0px 0px;
    margin: -1px -12px 0px 4px;
    font-size: 1rem;
}

.profile-list-container {
    color: white;
    background: #565656;
    position: absolute;
    left: -7px;
    box-shadow: 1px 2px 3px 0px gray;
    bottom: 33px;
}

.filter-profile-elem {
    padding: 10px;
}

.manage-profiles-btn:hover,
.filter-profile-elem:hover {
    background: #337ab7;
}

.manage-profiles-btn:active,
.filter-profile-elem:active {
    background: #1c5586;
}

.manage-profiles-btn {
    box-shadow: 0px -0px 3px 0px white;
    padding: 10px;
    text-decoration: underline;
    border-radius: 0px 3px 3px 0px;
    text-align: center;
}

.filter-profiles-list {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 250px;
    box-shadow: 0px -1px 4px 0px white;
    border-radius: 5px;
    padding-right: 12px;
}

::-webkit-scrollbar {
    width: 4px;
    overflow-y: scroll;
    background: gray;
    box-shadow: inset 0 0 4px #707070;
    border-radius: 10px;
    margin-left: 25px;
}

::-webkit-scrollbar-thumb {
    background: rgb(214, 214, 214);
    border-radius: 10px;
}