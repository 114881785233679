.rdo-chart-buttons-container {
    position: absolute;
    right: 20px;
}

.rdo-chart-main-button {
    box-shadow: 1px 1px 3px 0px darkgrey;
}

.rdo-chart-button {
    background: white;
    border: none;
    outline: none;
    font-size: 1.3rem;
    padding: 2px 10px;
    width: 100%;
}

.rdo-chart-button:hover {
    background: rgb(230, 230, 230);
}

.rdo-chart-button:active {
    background: rgb(184, 184, 184);
}