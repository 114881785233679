﻿.link {
	color: #2d6ca2;
	font-weight: normal;
}

.ovrl {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.5) url(/assets/images/loader-sm.gif) no-repeat center center;
}

.rdo-narrow-title {
	width: 80px !important;
}
.rdo-wide-title {
	width: 131px !important;
}
.rdo-table-title {
	text-overflow: clip !important;
	white-space: normal !important;

	div {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		line-height: 1.6em;
		height: 2.8em !important;
	}
}

.table {
	th:nth-child(1) {
		width: 120px;
	}

	th:nth-child(2),
	th:nth-child(3) {
		text-align: right;
	}
}
