@import '../../styles/_palette.scss';

#rental-asset,
#rental-asset-content {
	height: -webkit-calc(100% - 9rem);
	height: calc(100% - 9rem);
	overflow: hidden;
}

.panel-default {
	border: 1px solid;
	box-shadow: none;
}

.rdo-card-dashboard-default {
	color: $font-white;
	padding: 10px;

	.panel-heading {
		padding: 0;
		margin-top: -2px;
		margin-bottom: 10px;
		color: $font-white;

		.panel-title {
			font-size: 15px;
			font-weight: bold;
			text-transform: uppercase;
		}
	}

	.panel-body {
		padding: 0;
		min-height: 280px;

		&.large {
			min-height: 310px;
		}

		.form-group {
			margin-bottom: 0;

			.control-label {
				text-align: left;
				font-weight: normal;
			}

			.form-control-static {
				font-weight: normal;
				color: #798CAB;
			}
		}
	}
}

.rdo-card-default {
	background-color: $active-tab;

	.panel-heading {
		background-color: $active-tab;
	}
}

.rdo-card-default-blue {
	@extend .rdo-card-dashboard-default;
	@extend .rdo-card-default;
	color: #777;

	.panel-heading {
		color: #777;
	}
}
