
.dialog-container-border {
    background-color: #00000069;
    z-index: 1000002;
    position: fixed;
    height: 22vh;
    width: calc(40vw + 24px);
    height: calc(228px + 24px);
    top: calc(15vh - 12px);
    left: calc(30vw - 12px);
}


.dialog-container {
    z-index: 1000003;
    display: flex;
    position: fixed;
    width: 40vw;
    height: 228px;
    top: 15vh;
    left: 30vw;
    color: gray;
    padding: 15px;
    background: white;
    overflow-y: auto;
}


.black-background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: black;
    opacity: .6;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
}

.black-spinner {
    z-index: 10000001;
    opacity: .4;
}

.dialog-close-btn-border:hover {
    background-color: rgba(0, 0, 0, 0.60);
}

.dialog-close-btn-border:active {
    background-color: rgba(0, 0, 0, 0.80);
}

.dialog-close-btn:hover {
    background-color: rgb(236, 236, 236);
}

.dialog-close-btn:active {
    background-color: rgb(208, 208, 208);
}

.dialog-close-btn-border {
    background-color: rgba(0, 0, 0, 0.40);
    border-radius: 50%;
    padding: 5px;
    position: absolute;
    right: -9px;
    top: -9px;
    z-index: 10000000;
}

.dialog-close-btn {
    height: 25px;
    width: 25px;
    color: black;
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    user-select: none;
    cursor: pointer;
    font-size: 1.5rem;
}

.guidance-settings-title {
    font-size: 2rem;
    color: black;
    font-weight: bold;
}


.guidance-settings-body {
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0px;
    box-shadow: 0px 0px 2px 0px gray;
    position: relative;
    padding-bottom: 45px;
}

.guidance-settings-title {
    font-size: 1.5rem;
    color: black;
    margin-bottom: 10px;
}

.guidance-settings-body-title {
    color: black;
    font-size: 1.4rem;
    margin-bottom: 10px;
}

.guidance-settings-body-text {
    font-size: 1.3rem;
}

.yes-no-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 8px;
    bottom: 8px;
}

.yes-no-btn {
    padding: 5px 10px 5px 10px;
    text-align: center;
    margin-bottom: 4px;
    box-shadow: 0px 0px 1px 1px gray;
    user-select: none;
    cursor: pointer;
    text-transform: uppercase;
    background-color: white;
}

.yes-no-btn:hover {
    background-color: rgb(246, 246, 246);
}

.yes-no-btn:active {
    background-color: rgb(205, 205, 205);
}

.yes-btn {
    margin-left: 1px;
}

.no-btn {

}

.selected-yes-no-btn {
    color: #386aa1;
    background: linear-gradient(to top, white, #d5d5d5, #d5d5d5, #d5d5d5);
}

.selected-yes-no-btn:hover {
    background: #d5d5d5;
}

.selected-yes-no-btn:active {
    background: #c4c4c4;
}

.submit-guidance-settings-btn {
    text-align: center;
    text-transform: uppercase;
    background: #386aa1;
    padding: 10px;
    color: white;
    cursor: pointer;
    user-select: none;
}

.submit-guidance-settings-btn:hover {
    background: #4177b1;
}

.submit-guidance-settings-btn:active {
    background: #335f8e;
}

.guidance-settings-button-container {
    padding-bottom: 10px;
}
