.guided-tours-container {
    position: fixed;
    right: 0;
    bottom: 0;
    border-radius: 5px 0px 0px 0px;
    z-index: 1;
    padding: 7px;
    background-color: #222222e6;
    color: #fff;
    user-select: none;
}

.toggle-title {
    text-decoration: underline;
    margin-right: 5px;
}

.toggle-icon {
    transform: rotate(45deg);
    font-size: 1.5rem;
}

.guided-tours-content {
    padding: 10px 15px;
    max-width: max(25vw, 300px);
}

.guided-tours-content-title {
    font-size: 2rem;
    padding-bottom: 15px;
}

.guided-tours-content-description {
    font-size: 1.6rem;
    padding-bottom: 15px;
}

.guided-tours-content-list {
    font-size: 1.6rem;
}

.guided-tours-toggle {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.listed-guide {
    text-decoration: underline;
    cursor: pointer;
}
