.download-loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.60);
}
.hourglass {
    position: relative;
    margin-top: 10%;
    margin-left: 46%;
}

.file-name {
    opacity: .8;
}

.job-status-container {
    display: flex;
    align-items: center;
    height: 33.8px;
    margin-top: -1px;
    span {
        margin-right: 5px;
    }
}
#overlayImageCG {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.rdo-paginator {
    margin-top: -24px;
    margin-bottom: 11px;
}
