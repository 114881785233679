﻿.display-hidden {
    visibility: hidden;
}

#banner clientavatar i, #banner .nav-bar i {
    text-align: center;
    vertical-align: top;
    width: 3.5rem;
}

/* Twitter Typeahead Overrides */
span.twitter-typeahead .tt-suggestion {
	border-radius: 0;
	padding: 3px 0;

	&:hover {
		color: #fff !important;
		background-color: #00bcd4 !important;
	}
}

.tt-suggestion.tt-cursor, .tt-suggestion:hover {
	color: #fff !important;
	background-color: #00bcd4 !important;
}

.twitter-typeahead {
	.tt-input {
		width: 500px;
		min-height: 35px;
	}

	.tt-menu {
		width: 500px;
		margin-left: 0;
		margin-right: auto;
		padding: 3px;
		padding-bottom: 10px;
		background-color: #fff;
		border: 1px solid #ccc;
		border: 1px solid rgba(0, 0, 0, 0.2);
		-webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
		-moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
		box-shadow: 0 5px 10px rgba(0,0,0,.2);

		.tt-dataset {
			em {
				margin-left: 10px;
				float: left;
			}

			.searchbox-results-header {
				padding-left: 10px;
				display: inline-block;
				width: 245px;
				color: lightgray;
				top: -10px;
			}

			.searchbox-headers {
				font-weight: bold;
				color: #777777;
				display: inline-block;
				width: 120px;
				text-align: right;
			}

			.search-header {
				color: #555;
				background-color: #E0E0E0;
				padding: 3px;
				text-transform: uppercase;

				> img {
					position: relative;
					margin-right: 7px;
					width: 12px;
					height: 13px;
				}

				> span {
					position: relative;
					left: -4px;
				}
			}

			.tt-suggestion {
				.searchbox-result-column {
					width: 215px;
					margin-left: 10px;
					display: inline-block;
					padding-right: 5px;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					top: 2px;
					position: relative;
				}

				.searchbox-benchmark-column {
					display: inline-block;
					width: 137px;
					text-align: right;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					position: relative;
					top: 2px;
				}

				.searchbox-difference-column {
					display: inline-block;
					width: 115px;
					text-align: right;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					position: relative;
					top: 2px;

					&.searchbox-diff-below-true {
						color: #a94442;
					}
				}
			}
		}
	}
}
/* End Twitter Typeahead Overrides*/


#search-spinner {
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 1;
	width: 100%;
	text-align: center;
	display: none;

	> div {
		display: inline-block;
		width: 200px;
		margin-left: auto;
		margin-right: auto;
		top: 45px;
		position: relative;
		z-index: 100;
	}
}

.popover-search {
	position: fixed !important;
	top: 0;
	left: 0;
	z-index: 1060;
	color: black;
	display: none;
	max-width: 550px;
	min-width: 150px;
	padding: 1px;
	text-align: left;
	white-space: normal;
	background-color: #ffffff !important;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	/*border: 1px solid rgba(0, 0, 0, .2);*/
	border-radius: 0px !important;
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
	box-shadow: 0 5px 10px rgba(0, 0, 0, .2);

	&.left {
		margin-left: -10px;

		.arrow {
			top: 50%;
			right: -11px;
			margin-top: -11px;
			border-right-width: 0;
			border-left-color: #999;
			border-left-color: rgba(0, 0, 0, .25);

			&:after {
				right: 1px;
				bottom: -10px;
				content: " ";
				border-right-width: 0;
				border-left-color: #ffffff;
			}
		}
	}

	.arrow,
	.arrow:after {
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
	}

	.arrow {
		border-width: 11px;
	}

	.arrow:after {
		content: "";
		border-width: 10px;
	}
}
