﻿.change-log-entry {
	width: 80rem;
	color: #FFFFFF;

	&.compact {
		width: 35rem;
	}

	label {
		text-transform: uppercase;
		font-weight: normal;
	}

	textarea {
		width: 100%;
		height: 169px;
		resize: none;
	}

	.no-padding {
		padding: 0;
	}

	.modal-footer {
		padding: 10px;
		padding-right: 0;

		button {
			i {
				margin-right: 5px;
			}
		}
	}

	.modal-header {
		padding: 0;
	}

	.form-control[disabled] {
		background-color: #fff;
		font-weight: bold;
	}

	input {
		text-align: right;
	}

	.form-control[disabled] {
		background-color: #eee;
	}
}
