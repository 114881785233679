.filters-menu-btns {
    margin-left: 18px;
}

.filter-menu-btn {
    padding: 5px 10px;
    box-shadow: 1px 1px 3px 0px gray;
    outline: black;
    margin-left: 7px;
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    border: none;
}

.filter-menu-btn:hover {
    background-color: #ebebeb;
}

.filter-menu-btn:active {
    background-color: #ccc;
}

.save-icon {
    margin-right: 5px;
    font-size: 1.3rem;
}
