#changes-log {
  height: -webkit-calc(100% - 5rem);
  height: calc(100% - 5rem);
  overflow: hidden;
}

.changes-log-content {
  position: relative;

  .scroll {
    position: relative;
  }
}

.checkbox-container {
  position: absolute;
  top: 52px;
  left: 29px;
  display: flex;
  flex-direction: column;
  max-width: 34px;
  z-index: 1;
  flex-wrap: nowrap;
  gap: 0;

  .cursor-pointer {
    cursor: pointer;
  }
}

.checkbox-outer-div {
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.checkbox-item {
  display: inline-block;
  border-top: solid 1px transparent;
  // padding: 6.75px 8px; // Hack to make the checkboxes align
}

// This is a hack to make the left checkboxes in the table overlay align.
// on the Changes Log page.
@supports (-moz-appearance: none) {
  .checkbox-item {
    padding: 8px !important;
  }
}

input[type='checkbox'] {
  max-height: 12.129px;
  margin: 0 !important;
}

input[type='checkbox']:before {
  background-color: #fff;
  font-family: FontAwesome;
  font-size: 17px;
  display: inline-block;
}

input[type='checkbox']:before {
  content: '\f096';
} /* unchecked icon */
input[type='checkbox']:checked:before {
  content: '\f046';
} /* checked icon */

.disabled-icon {
  font-size: 14px;
  color: #b8b8b8;
  cursor: not-allowed;
}
