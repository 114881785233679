.maintenance-mode {
  // The <main> element has a strange positioning
  // using fixed to alleviate that problem.
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  z-index: 11;
  background: white;

  h1 {
    font-size: 3em;
    font-weight: bold;
    margin: 0 1rem;
    color: #7798BF;
    text-align: center;
  }

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @keyframes opacity-change {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .animated {
    animation-fill-mode: both;
    animation: 1s linear 0s 1 opacity-change;
  }

  p {
    font-size: 1.25em;
    margin: 3em 0 0;
  }

  time {
    font-size: 1.5em;
    font-weight: bold;
  }
}
