.filters-menu-btns {
    margin-left: 18px;
}

.filter-menu-btn {
    padding: 5px 10px;
    box-shadow: 1px 1px 3px 0px gray;
    outline: black;
    margin-left: 7px;
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    border: none;
}

.filter-menu-btn:hover {
    background-color: #ebebeb;
}

.filter-menu-btn:active {
    background-color: #ccc;
}

.save-icon {
    margin-right: 5px;
    font-size: 1.3rem;
}


.black-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: black;
    z-index: 1000;  
    margin: 0px;
    opacity: .3;
    padding: 0px;
}

.popup-container {
    position: fixed;
    width: 600px;
    height: 200px;
    left: calc(50vw - 300px);
    top: calc(35vh - 100px);
    color: gray;
    font-size: 1.5rem;
    font-weight: normal;
    /*display: flex;*/
    flex-direction: column;
    background: white;
    box-shadow: 1px 1px 3px 0px gray;
    padding: 15px;
    z-index: 1001;
    user-select: none;
    justify-content: space-between;
    border-radius: 5px;
}

.popup-title-section {
    display: flex;
    justify-content: space-between;
    font-size: 2rem;
    margin-bottom: 15px;
}

.popup-title {

}

.popup-close {
    color: black !important;
    cursor: pointer;
}

.popup-btn,
.popup-btn-disabled {
    box-shadow: 1px 1px 3px 0px gray;
    padding: 7px 17px;
    color: darkslategray;
    cursor: pointer;
}

.save-btn {
    margin-right: 15px;
}

.popup-btn:hover {
    background-color: rgb(238, 238, 238);
}

.popup-btn:active {
    background-color: rgb(231, 231, 231);
}

.popup-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.popup-check-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.popup-btns-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.popup-check-label {
    margin-left: 6px;
    padding-bottom: 2px;
    font-size: 1.6rem;
}

.profile-name-input {
    border: none;
    box-shadow: 1px 1px 3px 0px gray;
    padding: 3px 10px;
    border-radius: 3px;
    color: black;
    outline: none;
    margin-left: 10px;
    width: 100%;
    height: 4rem;
}

.profile-name-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
