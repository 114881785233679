﻿@import '../../../../styles/_palette.scss';

.uppercased {
	text-transform: uppercase;
}

.rental-growth > .table {
	border-width: 0px;
	width: 100%;
}

.rental-growth > .table tbody tr td {
	border: 1px solid #ddd;
}

.rental-growth > .table thead tr {
	background-color: $font-white;
	color: #000000;
}

.rental-growth > .table thead tr th {
	text-align: left;
	padding-left: 15px;
	border-bottom-width: 2px;
	border-left-width: 0px;
}

.rental-growth > .table th {
	border-top-width: 0px;
	border-left-width: 0px;
	border-bottom-width: 0px;
	border-right-width: 0px;
}

.rental-growth > .table th:nth-child(1),
.rental-growth > .table th:nth-child(2),
.rental-growth > .table th:nth-child(3),
.rental-growth > .table th:nth-child(4),
.rental-growth > .table th:nth-child(5),
.rental-growth > .table th:nth-child(6),
.rental-growth > .table th:nth-child(7),
.rental-growth > .table th:nth-child(8) {
	text-align: right;
	border-left-width: 0px;
	border-right-width: 0px;
}

.rental-growth > .table thead tr td:nth-child(1) {
	border-left-width: 0px;
	border-right-width: 0px;
}

.rental-growth > .table tbody tr td:nth-child(1) {
	text-align: left;
	border-left-width: 0px;
	border-right-width: 0px;
}

.rental-growth > .table tbody tr td:nth-child(8) {
	border-right-width: 0px;
}

.rental-growth > .table tbody tr td:nth-child(2),
.rental-growth > .table tbody tr td:nth-child(3),
.rental-growth > .table tbody tr td:nth-child(4),
.rental-growth > .table tbody tr td:nth-child(5),
.rental-growth > .table tbody tr td:nth-child(6),
.rental-growth > .table tbody tr td:nth-child(7),
.rental-growth > .table tbody tr td:nth-child(8) {
	text-align: right;
	border-left-width: 0px;
	border-right-width: 0px;
}

.rental-growth > .table tbody tr td:nth-last-child(1),
.rental-growth > .table tbody tr td:nth-last-child(2),
.rental-growth > .table tbody tr td:nth-last-child(3),
.rental-growth > .table tbody tr td:nth-last-child(4),
.rental-growth > .table tbody tr td:nth-last-child(5),
.rental-growth > .table tbody tr td:nth-last-child(6),
.rental-growth > .table tbody tr td:nth-last-child(7),
.rental-growth > .table tbody tr td:nth-last-child(8) {
	border-bottom-width: 0px;
}

.rental-growth > .table tbody tr td:nth-last-child(1),
.rental-growth > .table tbody tr td:nth-last-child(3),
.rental-growth > .table tbody tr td:nth-last-child(5),
.rental-growth > .table tbody tr td:nth-last-child(7) {
	border-left-color: #ddd;
	border-left-width: 1px;
}

.rental-growth > .table tr th:nth-last-child(1),
.rental-growth > .table tr th:nth-last-child(3),
.rental-growth > .table tr th:nth-last-child(5),
.rental-growth > .table tr th:nth-last-child(7) {
	border-left-color: #ddd;
	border-left-width: 1px;
}

.rental-growth-panel {
	margin-right: 0px !important;
	margin-left: 0px !important;
	margin-bottom: 20px;
	padding-top: 15px;
	height: 80px;

	.rental-growth-callout {
		font-size: 16px;
	}
}

.title-col {
	width: 150px;
}

.highlight {
	background-color: #f5f5f5;
}

.client-vs-bench-col {
	width: 235px;
}

.rental-growth-col-highlight {
	background-color: #f5f5f5;
}

@mixin up-or-down-selection($color) {
	border-color: $color;
	color: $color;
}

.increase {
	@include up-or-down-selection($primary-green);
}

.decrease {
	@include up-or-down-selection($primary-red);
}

@mixin panel-highlight($color) {
	background-color: $color;

	span {
		color: $font-white;
	}
}

.increase-highlight {
	@include panel-highlight($primary-green);
}

.decrease-highlight {
	@include panel-highlight($primary-red);
}

.flat-highlight {
	@include panel-highlight(#777);
}

.panel-default {
	border: none;
	box-shadow: none;
	text-align: center;
}

.rdo-card-metric {
	font-size: 25px;
}

a {
	color: $font-white;
	text-decoration: underline;
}

.rdo-card-dashboard-default {
	color: $font-white;
	padding: 10px;

	.panel-heading {
		padding: 0;
		margin-top: -2px;
		border: none;
		color: $font-white;

		.panel-title {
			font-size: 15px;
			font-weight: normal;
			text-transform: none;
		}
	}

	.panel-body {
		padding: 0;

		rdo-amount-difference {
			.text-danger {
				color: $font-white;
			}
		}
	}
}

.growth-table-title {
	display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.4em;
    max-height: 2.25em;
    white-space: normal;
    height: auto;
}

.card-set-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.flex-card {
		flex: 1 0 29%;
		margin: 0px 15px 15px 15px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.flex-card-4 {
		@extend .flex-card;
		flex: 1 0 20%;
		padding: 10px;
		padding-bottom: 12px;
		
		.panel-title {
			font-size: 15px;
			font-weight: normal;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			white-space: normal;
			padding: 0px;
		}
	}
}