﻿.rental-growth-charts {
    height: 375px;
    width: 100%;
}

.rdo-chart-margin {
  margin-bottom: 15px;
}

.chart-x-label
{
  font-size: 11px;
  text-align: center;
  width: 100%;
}

.chart-loader
{
    padding-left: 750px;
    padding-top: 87px;
}
