.grid-container {
    //width: 97.5vw;
    margin: 0 25px;
    height: 74vh;
    display: flex;
    border: 1px solid rgba(0, 0, 0, .4);
    transition: 1s ease;
  }
  
  .sidebar {
    width: 30%;
    height: 100%;
    background-color: #f5f5f5;
    transition: 0.5s ease;
    position: relative;
    box-shadow: 0px 1px 4px 1px rgba(0 ,0, 0, .3);
  }
  
  .main-content {
    width: 70%;
    height: 74vh;
    background-color: #ccc;
    transition: 0.5s ease;
  }
  
  .content_large {
    width: 100%;
  }
  
  .sidebar_small {
    width: 0%;
  }
  
  button {
    // position: absolute;
    border: none;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    box-shadow: 0px 1px 4px 1px rgba(0 ,0, 0, .3);
    left: 100%;
    top: 2.5rem;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 1;
  }

.e-pivotview {
    width: 100%;
    display: block;
    height: 100% !important;
}

.e-grid {
  height: 100% !important;
}

.e-grid-content {
  height: 100% !important;
}

.e-content {
  height:inherit;
}

.card-body {
  height: 800px;
}

.big-icon {
    font-size: 20px;
    width: 20px;
}
