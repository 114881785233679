body {
    margin:0px;
    padding:0px;
    border: solid 8px #293815;
}

.containerdiv {
    height: 98vh;
    width: 100%;
    background-color: #F3F8EC;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.invisible {
    display:none;
}

.visible {
    display:inherit;
}

#dimScreen {
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(155,155,155,1.0);
    z-index:1000;
}

#cookies-container {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.sample-btn {
    z-index: 10044;
    display: block;
    position: absolute;
    left: 30vw;
    top: 5px;
}
