﻿.three-state-item {
  background-color: #555555;
  border-color: white;
  color: white;
  min-height: 34px;
  overflow: hidden;
	text-overflow: ellipsis;

  &.active {
    background-color: white;
    border-color: white;
    color: #555555;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
}
