﻿#customer {
	height: -webkit-calc(100% - 5rem);
	height: calc(100% - 5rem);
	overflow: hidden;
}
#customer-content {
	height: -webkit-calc(100% - 9rem);
	height: calc(100% - 9rem);
	//height:100%;
	overflow: hidden;
}
#dummyDivForLength
{
    position: absolute;
    visibility: hidden;
    height: auto;
    width: auto;
    white-space: nowrap;
}