﻿#category,
#category-product-type {
  height: -webkit-calc(100% - 5rem);
  height: calc(100% - 5rem);
  overflow: hidden;
}

#category-product-type-content {
  height: -webkit-calc(100% - 10.6rem);
  height: calc(100% - 10.6rem);
  overflow: hidden;
}

#category-product-type-content > .scroll-container > div {
  padding-bottom: 50px;
}

.panel-group .rdo-panel {
  margin-bottom: 5px;
}

.rdo-col-card-offset {
  margin-bottom: 0;
}

/*
This container reserves a place for the icon
to load into preventing the ugly dom shift after it loads
 */
.product-types__icon-container {
  min-width: 40px;
  display: inline-block;
  height: 40px;
  overflow: hidden;
  vertical-align: middle;
  margin: 0 1rem 0 0;
}
