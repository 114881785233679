.allow-overflow {
  overflow: visible !important;
}

.make-relative {
  position: relative;
}

.non-expandable-spacer {
  display: inline-block;
  width: 15px;
  height: 13px;
}

.non-expanding {
  cursor: none;
}
