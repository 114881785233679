﻿#summary-charts {
	overflow: hidden;
}

.tab-content.chart {
	margin-bottom: 10px;
	padding-top: 10px;
}

li:not(:last-child) {
	border-bottom: 1px solid #ddd;
}

.chart-title {
	font-size: 14px;
	text-align: center;
	cursor: pointer;
}

.chart-drop-down-container {
	min-width: 300px;
}

li {
	color: #737373;
	padding: 5px;

	&:active,
	&.active {
		color: black;
		border: 1px solid lightgray;
		border-bottom-color: #fff;

		.tab-title {
			font-weight: 900;
		}
	}
}

.hover-only {
	&:active,
	&.active,
	&:focus,
	&.focus {
		pointer-events: none;
		border: none;
	}
}

a {
	text-decoration: underline;
	color: #337ab7;

	&:active,
	&.active {
		color: #2d6ca2;
		border: none;
		font-weight: bold;
	}
}

.dropdown-submenu {
	position: relative;
}

.dropdown-submenu > .dropdown-menu {
	top: 0;
	left: 100%;
	margin-top: -6px;
	margin-left: -1px;
}

.dropdown-submenu:hover > .dropdown-menu {
	display: block;
}

.dropdown-submenu > a:after {
	display: block;
	content: " ";
	float: right;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
	border-width: 5px 0 5px 5px;
	border-left-color: #ccc;
	margin-top: 5px;
	margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
	border-left-color: #fff;
}

.dropdown-submenu.pull-left {
	float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
	left: -100%;
	margin-left: 10px;
}

.dropdown-submenu,
.dropdown {
	a {
		text-decoration: none;
	}
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
	border: none;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
	border: none;
}

.dropdown-toggle {
	text-decoration: none;
	cursor: pointer;
	background-color: initial;
	border: none;

	&:focus,
	&:hover {
		background-color: initial;
		border: none;
	}
}


.caret {
	margin-left: 5px;
	float: right;
	margin-top: 5px;
}
