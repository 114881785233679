// button {
//     height: 32px;
// }

.client-selector {
  height: 32px;
  float: right;
}

.multiclient-select-button {
  width: 60% !important;
  margin-left: 20% !important;
  margin-right: 20%;
  margin-bottom: 5px;
}

.modal-footer {
  padding: 10px;
  padding-right: 0;

  button {
    i {
      margin-right: 5px;
    }
  }
}

.file-input {
  display: none;
}

.icon_large {
  padding-top: 12px;
  padding-right: 24px;
  font-size: 32pt;
  color: lightblue;
}


.dialog-title {
  // white-space: nowrap;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 16pt;
  padding-bottom: 12px;
}

.dialog-subtitle {
  // white-space: nowrap;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 12pt;
  padding-left: 24px;
  color: dimgray;
}

.embedded-dialog-subtitle {
  // white-space: nowrap;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 13px;
  padding-left: 24px;
  padding-right: 24px;
  color: dimgray;
  border-top: 1px dimgray;
}

.dialog-input {
  /*border: 1px solid red;*/
  border-radius: 4px;
}

.dialog-button {
  -webkit-font-smoothing: antialiased;
  border: 1px solid;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 14px;
  font-weight: normal;
  justify-content: center;
  line-height: 1.143em;
  outline: none;
  padding: 6px 12px 4px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  background-color: white;
  border-color: transparent;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
}

.dialog-button-default {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
  -webkit-font-smoothing: antialiased;
  border: 1px solid;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 14px;
  font-weight: normal;
  justify-content: center;
  line-height: 1.143em;
  outline: none;
  padding: 6px 12px 4px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

button {
  background-color: transparent;
}

.dialog-button:hover {
  border: 1px solid transparent;
  background-color: rgba(0, 0, 0, 0.0348);
}

.dialog-button-default:hover {
  background-color: #22517a;
  border-color: #1a3f5e;
}

.dialog-button[disabled],
.dialog-button[disabled]:hover {
  border: 1px solid transparent;
  color: gray;
}

.dialog-header {
  border-radius: 4px;
}

.dialog-content {
  border: 1px solid #e0e0e0;
  background: #fff;
  color: rgba(0, 0, 0, .87);
  flex-direction: column;
  width: 100%;

  // margin-top: 24px;
  .d-content {
    flex: 1;
    flex-direction: row;
  }
}

.restricted-or-public-select {
  margin-top: 5px;
  display: flex;
  align-items: center;
}


.dialog-content-header {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
  font-weight: normal;
  height: 28px;
  overflow: hidden;
  padding: 7px 0 0 15px;
  text-align: left;
  text-overflow: ellipsis;
  word-break: break-all;
  line-height: 1.5em;
  text-transform: none;
  margin-bottom: 24px;
}

td {
  padding-left: 8px;
  padding-right: 8px;
}

.permissions-table {
  padding-top: 10px;
}

.permissions-table tr {
  padding-bottom: 3px;
}

.permissions-table tr td {}

.dialog-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
}

.materialesque-select {
  height: 32px;
}

.scrollingBody {
  overflow: auto;
  height: calc(87vh - 320px);
}

.scrollingBodyTall {
  overflow: auto;
  height: calc(87vh - 240px);
}

.mat-selection-list {
  // width: 50%;
  height: calc(87vh - 260px);
  overflow: scroll;
  overflow-x: hidden !important;
}

.embedded-mat-selection-list {
  height: 52vh !important;
}

.mat-list {
  // width: 50%;
  height: calc(87vh - 260px);
  overflow: scroll;
  overflow-x: hidden !important;
}

.embedded-mat-list {
  height: 52vh !important;
}

.mat-tree {
  // width: 50%;
  height: calc(87vh - 260px);
  overflow: scroll;
  overflow-x: hidden !important;
}

.embedded-mat-tree {
  height: 52vh !important;
}

.list-item {
  font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif !important;
  font-size: 1em !important;
  height: 33px !important;
}

.list-item-right {
  font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif !important;
  font-size: 1em !important;
  height: 33px !important;
  display: flex;
  flex-direction: row;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.select-all {
  font-weight: bold;
}

.flex-container {
  display: flex;
}

.flex-child {
  flex: 1;
}

.remove-button {
  cursor: pointer;
  font-size: 20px;
  color: #3f51b5;
}

.disabled-remove-button {
  cursor: not-allowed;
  font-size: 20px;
  color: gray;
}

.remove-button-container {
  padding: 5px;
}

.unselect-all {
  font-weight: bold;
}

.delete-file-button {
  align-self: flex-start;
}

.cancel-button {
  align-self: flex-end;
  margin-left: auto;
}

.save-button {
  align-self: flex-end;
}

.disabled,
.disabled:hover {
  background-color: gray;
  cursor: default;
}

mat-tree-node {
  min-height: unset !important;
  height: 32px;
}

mat-tree-node[aria-level="2"],
mat-tree-node[aria-level="3"] {
  padding-left: 15px !important;
}

mat-tree-node[aria-level="3"] {
  padding-left: 30px !important;
}

mat-tree {
  padding-top: 10px;
}

.mat-tree-node {
  font-size: 1em !important;
}

//   .material-icons{
//     opacity:0;
// }

.dialog-container {
  height: 85vh;
  max-width: 100% !important;
}

.embedded-dialog-container {
  height: 63vh;
  max-width: 100% !important;
}

.embedded-dialog-header {
  border-top: dimgray solid 0.5px;
  padding-top: 8px;
}

.embedded-dialog-content {
  margin-top: 5px !important;
}

.parent-node {
  margin-top: 4px;
}

.disabled-trashcan {
  pointer-events: none;
  opacity: 0.5;
}

.disabled-area {
  pointer-events: none;
  opacity: 0.5;
}

.no-restricted-users-message {
  font-weight: 13px;
  display: block;
}

.select-class {
  font-size: 1.5rem;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 5px;
}

.lock-icon {
  color: #3f51b5;
  margin-bottom: 9px;
  margin-right: 4px;
  padding-top: 7px;
  height: 31px;
}

:host ::ng-deep .btn-caret {
  margin-top: 5px !important;
}

:host ::ng-deep .btn-label {
  margin-right: 15px !important;
  vertical-align: middle !important;
}

:host ::ng-deep .file-manager-dropdown {
  min-width: 310px !important;
  text-align: left !important;
}

:host ::ng-deep .fa-info-circle {
  color: #2d6ca2 !important;
}


.smaller-dialog-content {
  height: calc(100vh - 445px);
}

.smaller-mat-tree {
  height: calc(92vh - 465px) !important;
}

.smaller-simple-list {
  height: calc(92vh - 461px) !important;
}

.smaller-selected-list {
  height: calc(92vh - 460px) !important;
}

.responsive-btn {
  height: 30px;
  font-size: 15px;
  padding: 8px;
  margin: 0;
}

.responsive-footer {
  position: fixed;
  bottom: min(6vh, 82px);
  width: min(76vw, 101rem);
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notification-checkbox {
  margin-left: auto;
  float: right;
  padding-right: 10px;
}

.selection-box-inner-title {
  font-size: 14px;
  font-weight: bold;
}

.selected-box-inner-title {
  font-size: 14px;
  font-weight: bold;
  margin-left: auto;
  justify-content: flex-end;
  display: flex;
  margin-right: 12px;
}

.notification-message-box {
  margin-top: 10px;
}

.notification-input {
  resize: none;
}

.notification-label {
  font-size: 14px;
}

.smaller-notification-box {
  margin: 10px !important;
}