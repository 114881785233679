/*@media (min-width: 481px) { // down to 481 (desktop)
    .modal-client-select {
        top: 31%;
        z-index: 20000 !important;
        box-shadow: 0 0 10px 4px dimgrey;
        width: 50%;
        max-width: 50%;
        margin-left: 25%;
        margin-right: 25%;
    }

    .auth0-lock-header-logo {
        width: 90% !important;
    }

    #multiclient-dialog-backdrop {
        position: absolute;
        width: 10000px !important;
        height: 10000px !important;
        background-color: black;
        opacity: 0.0; // controlled dynamically
        z-index: 10000;
    }
}

@media (max-width: 480px) { // up to 400px - ie mobile
    .modal-client-select {
        top: 31%;
        z-index: 20000 !important;
        box-shadow: 0 0 10px 4px dimgrey;
        width: 90%;
        max-width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        position: absolute !important;
    }

    .auth0-lock-header-logo {
        width: 90% !important;
    }

    #multiclient-dialog-backdrop {
        position: absolute;
        width: 100% !important;
        height: 100% !important;
        background-color: black;
        opacity: 0.6;
        z-index: 10000;
    }
}*/

/*.row {
    height:32px;
}*/
button {
    height: 32px;
}

.client-selector {
    height: 32px;
    float: right;
}

    .multiclient-select-button {
        width: 60% !important;
        margin-left: 20% !important;
        margin-right: 20%;
        margin-bottom: 5px;
    }

    .modal-footer {
        padding: 10px;
        padding-right: 0;

        button {
            i {
                margin-right: 5px;
            }
        }
    }
