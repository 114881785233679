.gray-background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    opacity: .5;
    background: black;
    z-index: 1000;
    top: 0px;
    left: 0px;
}

.save-spinner {
    position: absolute;
    left: calc(50vw - 12px);
    top: calc(50vh - 12px);
    z-index: 1003;
    color: lightgray;
}
