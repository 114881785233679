th,
td {

  padding-left: 5px;
  padding-right: 5px;
  vertical-align: text-top;
}

.dialog-content {
  height: 90% !important
}

.e-list-text {
  font-weight: 700 !important;
}

label {
  font-weight: 500 !important;
}

.e-error {
  font-weight: 600 !important;
  padding-left: 30px;
}

.e-success {
  font-weight: 600 !important;
  padding-left: 30px;
}

.sched-opts-container {
  overflow: hidden;
  opacity: 1;
}

.sched-opts-container-max-height {
  height: calc(100vh - 142px);
}

.sched-opts-table {
  opacity: 0.7;
}

.sched-dial-head {
  max-width: 1050px;
}

.sched-dial-container {
  max-height: calc(95vh);
  z-index: 1000003;
  left: 0px;
  top: 0px;
  position: relative;
  max-width: 1050px;
}

.sched-dial-meta-container {
  z-index: 1000003;
  display: flex;
  position: fixed;
}

.scheduler-overlay {
  z-index: 1000002;
  display: block;
  position: absolute;
}

.e-dialog .e-dlg-content {
  padding-top: 0px !important;
}

.e-dialog .e-dlg-header-content {
  padding: 9px;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #b7afaf;
}


mat-label {
  font-size: 12px;
}

.radio-label {
  font-size: 12px !important;
  margin-top: 8px !important;
}