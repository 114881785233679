﻿$text-align: right;

.table {
  th:nth-child(2) {
    width: 110px;
    text-align: $text-align;
  }

  th:nth-child(3) {
    width: 130px;
    text-align: $text-align;
  }
}
