﻿#sales-rep {
	height: -webkit-calc(100% - 5rem);
	height: calc(100% - 5rem);
	overflow: hidden;
}
#sales-rep-content {
	 height: -webkit-calc(100% - 9rem);
	 height: calc(100% - 9rem);
	//height:100%;
	overflow: hidden;
}
