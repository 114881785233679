﻿@import '../../../../styles/_palette.scss';

.rdo-card {
  > .table {
    th {
      color: #777777;
    }

    th:nth-child(2) {
      text-align: right;
    }

    td:nth-child(2),
    td:nth-child(3) {
      text-align: right;
    }
  }
}

.rdo-card-default {
	border: 1px solid transparent;

	table {
		border-top: none;
	}

	.panel-heading {
		border-radius: 0;
		text-align: center;
		padding: 10px;
		border: none;
		color: $font-white;

		.panel-title {
			font-size: 15px;
			font-weight: normal;
			text-transform: none;

			a {
				color: $font-white;
				text-decoration: none;

				&.selected {
					text-decoration: underline;
				}
			}
		}
	}

	.panel-body {
		padding: 0;

		rdo-amount-difference {
			.text-danger {
				color: $font-white;
			}
		}
	}
}

.rdo-card-bench {
	table {
		border: 1px solid $accent-blue;
	}

	.panel-heading {
		background-color: $accent-blue;
	}
}

.rdo-card-book {
	table {
		border: 1px solid $primary-blue;
	}

	.panel-heading {
		background-color: $primary-blue;
	}
}