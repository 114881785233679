﻿/*#product-type-grid {
  tbody tr:nth-child(1) {
    background-color: #f5f5f5;
    font-weight: bold;
    text-transform: uppercase;
  }
}*/

.load-all-btn {
  background: white;
  color: #386aa1;
  border: 1px solid #386aa1;
  margin-bottom: 3px;
}

.load-all-btn:hover,
.load-all-btn:active {
  background: #386aa1;
  color: white;
}
