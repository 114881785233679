﻿@import '../../styles/functions/index.scss';

$font-white: #fff;
$default-font: 'Roboto', sans-serif;
$dark-font: #333333;
$link-hover: #64b5f6;
$success: #4caf50;
$danger: #f44336;
$warning: #ff9800;
$header-height: 35px;
$header-height-with-logo: 50px;

.client-logo-present {
  #banner {
    height: toRem($header-height-with-logo);
  }
}

#banner {
  background-color: #555555; // rgba(85, 85, 85, 1);
  height: toRem($header-height);
  position: relative;
  z-index: 10;
  align-items: center;
  flex-wrap: nowrap;
  box-sizing: border-box;

  img.client-logo {
    max-height: 38px;
    max-width: 152px;
    width: auto;
    display: inline-block;
    overflow: visible;
    margin: 0 0.5rem 0 1rem;
  }

  .logo {
    position: relative;

    &::after {
      content: '';
      height: -webkit-calc(100% - 1.5rem);
      height: calc(100% - 1.5rem);
      position: absolute;
      right: 0;
      top: 0.75rem;
    }

    span {
      padding: 5px;
    }
  }

  .divider {
    display: inline-block;
    vertical-align: middle;
    height: ($header-height * 0.6);
    margin: 0 3px;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.4);
  }
}

.client-logo-present {
  #banner .avatar a,
  #banner .avatar i,
  #banner .logo,
  #banner .nav-bar a,
  #banner .nav-bar i,
  #banner .version-info {
    line-height: toRem($header-height-with-logo);
  }
}

#banner .avatar a,
#banner .avatar i,
#banner .logo,
#banner .nav-bar a,
#banner .nav-bar i,
#banner .version-info {
  color: $font-white;
  display: inline-block;
  line-height: toRem($header-height);
}

#banner .avatar i,
#banner .nav-bar i {
  text-align: center;
  vertical-align: top;
  width: 3.5rem;
}

#banner .nav-menuItem {
  background-color: rgba(85, 85, 85, 0.8);
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
  line-height: 3.5rem;
  color: $font-white;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &:hover,
  &.active {
    background-color: #d77700;
  }

  a {
    font-size: 20px;
    color: $font-white;

    &:hover {
      text-decoration: none;
    }

    span {
      font-size: 12px;
      padding-left: 5px;
    }
  }
}

#banner .alerts {
  a span {
    font-size: 20px;
    padding-left: 0;

    i {
      margin-top: -3px;
    }
  }
}

.fa-globe {
  vertical-align: middle;
}

@media (min-width: 481px) {
  // down to 481 (desktop)
  .modal-client-select {
    top: 31%;
    z-index: 20000 !important;
    box-shadow: 0 0 10px 4px dimgrey;
    width: 50%;
    max-width: 50%;
    margin-left: 25%;
    margin-right: 25%;
  }
  .auth0-lock-header-logo {
    width: 90% !important;
  }
  #multiclient-dialog-backdrop {
    position: absolute;
    width: 10000px !important;
    height: 10000px !important;
    background-color: black;
    opacity: 0; // controlled dynamically
    z-index: 10000;
  }
}

@media (max-width: 480px) {
  // up to 400px - ie mobile
  .modal-client-select {
    top: 31%;
    z-index: 20000 !important;
    box-shadow: 0 0 10px 4px dimgrey;
    width: 90%;
    max-width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    position: absolute !important;
  }
  .auth0-lock-header-logo {
    width: 90% !important;
  }
  #multiclient-dialog-backdrop {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    background-color: black;
    opacity: 0.6;
    z-index: 10000;
  }
}

.multiclient-select-button {
  width: 60% !important;
  margin-left: 20% !important;
  margin-right: 20%;
  margin-bottom: 5px;
}

.btn-group-vertical {
  width: 100%;
}

#banner .trial-mode {
  position: relative;
  width: 20px;
  background: #f0ad4e;

  div {
    margin-top: 1px;
    color: #fff;
    margin-left: 17px;
    padding: 0;
    text-transform: uppercase;
    float: left;
    width: 35px;
    -ms-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -ms-transform-origin: left top 0;
    -moz-transform-origin: left top 0;
    -webkit-transform-origin: left top 0;
    transform-origin: left top 0;
  }
}

.alerts {
  .fa-stack {
    &:after {
      position: absolute;
      right: -5%;
      top: 5%;
      content: attr(data-count);
      font-size: 40%;
      padding: 0.6em;
      border-radius: 10px;
      line-height: 0.75em;
      color: $font-white;
      background: $danger;
      text-align: center;
      min-width: 2em;
      font-weight: bold;
    }
  }
}

#banner .version-info {
  color: $dark-font;
  cursor: default;
}

#banner .nav-bar a:hover,
#user-menu.active ~ #banner [data-ref='user-menu'],
#alert-menu.active ~ #banner [data-ref='alert-menu'],
#change-log-menu.active ~ #banner [data-ref='change-log-menu'] {
  background-color: #555;
}

#user-menu,
#alert-menu,
#change-log-menu {
  &.active {
    background-color: #555;
    border: 1px solid #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
}

#user-menu {
  right: 0;
  top: 3.5rem;
  width: 25rem;
  z-index: 10;

  a {
    display: block;
    padding: 1.5rem 2rem;
    color: $font-white;

    &:hover {
      color: $link-hover;
      text-decoration: none;
    }
    /*i {
			margin-right: 5px;
		}*/
  }
}

.client-logo-present #user-menu {
  top: 5rem;
}

.header-searchbox-container {
  margin: 0 1rem;
}

#alert-menu {
  right: 0;
  top: 3.5rem;
  width: 35rem;
  height: 100%;
  z-index: 10;

  &.active {
    box-shadow: none;
  }

  .checkbox {
    margin-top: 0;
    padding-left: 10px;

    @media only screen and (max-width: 992px) {
      margin-left: 10px;
    }

    @media only screen and (max-width: 768px) {
      margin-left: 10px;
    }

    @media only screen and (max-width: 480px) {
      margin-left: 10px;
    }

    @media only screen and (max-width: 320px) {
      margin-left: 10px;
    }

    label {
      display: inline;
    }

    span {
      display: inline-block;
      line-height: 30px;
      padding-left: 10px;
      position: relative;

      &:before {
        background-color: $font-white;
        border: 1px solid #f5f5f5;
        border-radius: 0px;
        content: '';
        display: inline-block;
        height: 31px;
        left: 0;
        margin-left: -30px;
        position: absolute;
        width: 31px;
      }

      &:after {
        color: #555555;
        display: inline-block;
        height: 26px;
        left: 0;
        margin-left: -30px;
        padding-left: 6px;
        padding-top: 1px;
        position: absolute;
        top: 0;
        width: 26px;
      }
    }

    input[type='checkbox']:not(hidden) {
      opacity: 0;

      &:disabled ~ span {
        opacity: 0.65;
      }

      &:disabled ~ span::before {
        background-color: #f5f5f5;
        cursor: not-allowed;
      }

      &:checked ~ span::after {
        content: '\f00c';
        font-family: 'FontAwesome';
        font-size: 20px;
      }
    }
  }

  label {
    text-transform: uppercase;
    font-weight: normal;
    color: $font-white;
  }

  span {
    i {
      color: $font-white;
    }
  }

  .dropdown-block {
    display: block;

    .btn[disabled] {
      opacity: 0.75;
    }

    .btn-default {
      background-color: $font-white;
      width: 100%;
      text-align: left;

      i {
        color: #555;
      }

      .caret {
        margin-top: 8px;
        float: right;

        @-moz-document url-prefix() {
          margin-top: -10px;
        }
      }

      &:hover {
        background-color: $font-white;
      }

      &.active {
        background-color: $font-white;
      }
    }

    .dropdown-toggle {
      &:focus {
        border-color: #66afe9;
        outline: 0;
        box-shadow:
          inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 8px rgba(102, 175, 233, 0.6);
      }
    }
  }

  .dropdown-menu {
    width: 101%;
    margin-left: -1px;

    .input-group {
      .form-control {
        border-radius: 0;
      }
    }

    .dropdown-item {
      padding: 0 3px;
    }

    .dropdown-item a i {
      color: #555;
    }

    .input-group-addon {
      border-radius: 0;

      i {
        color: #555;
      }
    }

    > li > a {
      padding: 3px 10px;

      &:focus {
        background-color: transparent;
      }

      &:hover {
        color: #fff;
        background-color: #00bcd4;
      }
    }
  }

  .notification-list {
    background-color: $font-white;
    padding: 5px;
    overflow-y: auto;
    top: 195px;
    position: fixed;
    bottom: 40px;

    .hide {
      background-color: transparent;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      top: 230px;
      bottom: 0;
    }

    > li {
      margin-bottom: 10px;
      cursor: pointer;

      &:last-child:not(:first-child) {
        margin-bottom: 0;
      }

      &.active {
        background-color: #dff0d8;
      }

      &.hide {
        display: none;
      }

      &:hover {
        color: $font-white;
        background-color: #2196f3;

        .media {
          color: $font-white;

          .text-default,
          .text-success,
          .text-danger,
          .text-warning {
            color: $font-white;
          }

          .text-white {
            color: $dark-font;
          }

          .icon-category svg {
            path {
              fill: $font-white;
            }
          }
        }
      }

      .media {
        margin-top: 2px;
        color: $dark-font;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 5px;

        .media-right {
          i {
            margin-right: 2px;
          }
        }

        .media-body {
          .heading {
            font-weight: bold;
            margin: 0;
          }

          .sub-text {
            font-size: 12px;
            text-transform: none;
            margin: 0;
          }

          .truncate {
            width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .rdo-card-metric {
            font-size: 12px;
          }
        }

        .icon-category svg {
          margin-left: 2px;
          height: 18px;
          width: 18px;
          vertical-align: initial;

          path {
            fill: $dark-font;
          }
        }

        .text-default {
          color: $dark-font;
        }

        .text-success {
          color: $success;
        }

        .text-danger {
          color: $danger;
        }

        .text-warning {
          color: $warning;
        }

        .text-white {
          color: $font-white;
        }
      }
      // CSS Hacks for IE and Firefox
      @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        margin-top: -10px;
      }

      @-moz-document url-prefix() {
        .media {
          margin-top: 5px;
        }
      }
    }
  }
}

#change-log-menu {
  right: 0;
  top: 3.5rem;
  z-index: 10;
  color: #ffffff;
}

.client-logo-present #change-log-menu {
  top: 5rem;
}
