﻿@import '../../styles/_palette.scss';

.transaction-box {
  margin-bottom: 15px;
}

.filter-breadcrumb {
  //min-height: 50px;
  height: 50px;
}

.filter {
  background-color: #ffffff;
  position: relative;

  input {
    border: 0;
  }

  .input-group {
    border-bottom: 2px solid #f5f5f5;
  }

  .filter-comparison-mode-bench {
    background-color: $accent-blue;
    color: $font-white;
  }

  .filter-comparison-mode-book {
    background-color: $primary-blue;
    color: $font-white;
  }

  .filter-search {
    margin-left: auto;
    margin-right: 0;
    padding-bottom: 0.4rem;
    padding-top: 0.4rem;
  }

  .filter-bar-item {
    background-color: #f5f5f5;
    border-radius: 0px;
    display: inline-block;
    margin-left: 1em;
    display: inline-block;

    > a,
    > span {
      display: inline-block;
      line-height: 2rem;
      padding: 0.5rem 1rem;
    }
  }
}

.buttons-row {
  margin-top: 10px;
}

.client-logo-present .filter-dark-background-header {
  height: 50px;
}

.client-logo-present .filter-menu {
  top: 50px;
}

.filter-menu {
  top: 35px;
  width: 100%;
  white-space: nowrap;
  color: #ffffff;
  /*overflow-y: auto;
	overflow-x: hidden;*/
  /*overflow: auto;*/

  &.active {
    border: 1px solid #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    z-index: 5;
  }

  @media only screen and (max-width: 992px) {
    max-height: 90%;
  }

  @media only screen and (max-width: 768px) {
    max-height: 85%;
  }

  @media only screen and (max-width: 480px) {
    max-height: 80%;
  }

  @media only screen and (max-width: 320px) {
    max-height: 75%;
  }

  .modal-footer {
    padding: 10px;
    padding-right: 0;

    button {
      max-width: 25%;
      overflow: hidden;
      text-overflow: ellipsis;

      i {
        margin-right: 5px;
      }
    }
  }

  .filter-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .container-left,
  .container-right,
  .container-center {
    padding: 0px 20px;
    margin-top: 10px;
    min-height: 0;
    border: none;

    &.large {
      min-height: 0;

      @media only screen and (min-width: 1200px) {
        min-height: 314px;
      }

      @media only screen and (min-width: 992px) {
        min-height: 314px;
      }
    }

    &.extra-large {
      min-height: 0;

      @media only screen and (min-width: 992px) {
        min-height: 335px;
      }
    }

    &.extra-extra-large {
      min-height: 0;

      @media only screen and (min-width: 992px) {
        min-height: 385px;
      }
    }

    @media only screen and (min-width: 1200px) {
      min-height: 285px;
    }

    @media only screen and (min-width: 1200px) {
      min-height: 285px;
    }
  }

  .container-center {
    border-right: none;

    @media only screen and (min-width: 992px) {
      border-right: 1px solid #fff;
      border-left: 1px solid #fff;
    }

    .checkbox {
      margin-left: 5px;
      margin-top: 22px;

      @media only screen and (min-width: 1200px) {
        margin-left: 10px;
      }
    }
  }

  label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .radiobutton-container {
    margin: 0px 0px;
    padding: 0px 0px;
  }

  .form-group {
    label {
      text-transform: uppercase;
      font-weight: normal;
    }
  }

  .unit-filters {
    .header {
      text-transform: uppercase;
      font-weight: normal;
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .transaction-filters {
    .header {
      text-transform: uppercase;
      font-weight: normal;
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .general-filters {
      margin-top: 7px;

      .form-group {
        display: inline-block;
      }
    }
  }

  .transaction-box {
    min-width: 150px;

    @media only screen and (max-width: 992px) {
      margin-bottom: 10px;
    }

    @media only screen and (max-width: 768px) {
      margin-bottom: 10px;
    }

    @media only screen and (max-width: 480px) {
      margin-bottom: 10px;
    }

    @media only screen and (max-width: 320px) {
      margin-bottom: 10px;
    }

    rdo-three-state-checkbox {
      display: block;
    }
  }

  .checkbox {
    margin-left: 10px;
    padding-top: 12px;

    label {
      display: inline;
    }

    span {
      display: inline-block;
      line-height: 30px;
      padding-left: 10px;
      position: relative;

      div {
        display: -webkit-inline-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        line-height: 1.7rem;
        white-space: normal;
        vertical-align: middle;
      }

      &.text-wrap {
        width: 200px;
        line-height: inherit;
        white-space: pre-wrap;
      }

      &:before {
        color: red !important;
        background-color: #ffffff;
        border: 1px solid #f5f5f5;
        border-radius: 0px;
        content: '';
        display: inline-block;
        height: 31px;
        left: 0;
        margin-left: -30px;
        position: absolute;
        width: 31px;
      }

      &:after {
        color: #555555;
        display: inline-block;
        height: 26px;
        left: 0;
        margin-left: -30px;
        padding-left: 6px;
        padding-top: 1px;
        position: absolute;
        top: 0;
        width: 26px;
      }
    }

    input[type='checkbox']:not(hidden) {
      opacity: 0;

      &:disabled ~ span {
        opacity: 0.65;
      }

      &:disabled ~ span::before {
        background-color: #f5f5f5;
        cursor: not-allowed;
      }

      &:checked ~ span::after {
        content: '\f00c';
        font-family: 'FontAwesome';
        font-size: 20px;
      }
    }
  }

  .dropdown-block {
    display: block;

    .btn[disabled] {
      opacity: 0.75;
    }

    .btn-default {
      background-color: $font-white;
      width: 100%;
      text-align: left;

      i {
        color: #555;
      }

      .caret {
        margin-top: 8px;
        float: right;

        @-moz-document url-prefix() {
          margin-top: -10px;
        }
      }

      &:hover {
        background-color: $font-white;
      }

      &.active {
        background-color: $font-white;
      }
    }

    .dropdown-toggle {
      &:focus {
        border-color: #66afe9;
        outline: 0;
        box-shadow:
          inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 8px rgba(102, 175, 233, 0.6);
      }
    }
  }

  .dropdown-menu {
    min-width: 100%;

    .input-group {
      .form-control {
        border-radius: 0;
      }
    }

    .dropdown-item {
      padding: 0 3px;
    }

    .dropdown-item a i {
      color: #555;
    }

    .input-group-addon {
      border-radius: 0;

      i {
        color: #555;
      }
    }

    > li > a {
      padding: 3px 10px;

      &:focus {
        background-color: transparent;
      }

      &:hover {
        color: #fff;
        background-color: #00bcd4;
      }
    }
  }
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.buttons-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 7px;
  box-shadow: 0px -1px 0px 0px white;
  padding: 10px 0px 2px 0px;
}

.filter-right-btns {
  button {
    margin-left: 7px;

    i {
      margin-right: 5px;
    }
  }
}

.filter-left-btns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
}

.filter-dark-background {
  background-color: black;
  opacity: 0.3;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
}

// When .logo-present is added to the dom then height becomes 50px;
.filter-dark-background-header {
  @extend .filter-dark-background;
  width: 100vw;
  height: 35px;
  z-index: 10;
  top: 0;
  left: 0;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.buttons-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 7px;
  box-shadow: 0px -1px 0px 0px white;
  padding: 10px 0px 2px 0px;
}

.filter-right-btns {
  button {
    margin-left: 7px;

    i {
      margin-right: 5px;
    }
  }
}

.filter-left-btns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
}

.list-container {
  overflow-x: auto;
  white-space: nowrap;
  overflow-y: hidden;
}

.list-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.list-container {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.list {
  display: flex;
  transition: transform 0.3s ease;
}

button {
  cursor: pointer;
}
