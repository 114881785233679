input {
    height: 32px !important;
    min-width: 142px !important;
}

button {
    height: 32px !important;
    min-width: 142px !important;
}

select {
    height: 32px !important;
    min-width: 192px;
}

