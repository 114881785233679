﻿a {
	outline: none !important;
}

.dropdown-inline {
	display: inline-block;
}

.dropdown-toggle .caret {
	margin-left: 4px;
	white-space: nowrap;
	display: inline-block;
}

.btn-label {
	width: 90%;
	display: inline-block;
	vertical-align: inherit;
}

.btn-caret {
    float: right;
    display: inline-block;
}
