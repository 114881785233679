$dim: 6px;
// Very specific global styles need to be overridden
// thus all the importants.
.moveable-column-arrows__container {
  display: flex !important;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  position: relative !important;
  overflow: visible !important;
  width: calc(100% + 5px) !important;
  height: 100%;
  padding: 0;
  margin: 0;
  left: -5px;

  &--static {
    padding: 0 5px 0 3px;
  }
}

.moveable-column-arrows__arrow-container {
  display: flex !important;
  flex: none;
  align-items: center;
  flex-basis: 13px;
}

.moveable-column-arrows__content {
  padding: 0 5px;
  display: inline-block;
  flex-basis: auto;
}

.moveable-column-arrows__arrow {
  position: relative;
  display: block;
  width: $dim;
  height: $dim;
  border: none;
  background: none;

  & i {
    position: relative;
    font-size: 18px;
    width: $dim;
    height: $dim;
  }
}

.arrow--right {
  top: -1px;
  & i {
    top: -3px;
    transform: rotate(90deg);
  }
}

.arrow--left {
  & i {
    top: 0px;
    transform: rotate(-90deg);
  }
}
