.download-loader {
    position: relative;
    top: 79;
    padding-left: 50%;
    height: 100%;
    width: 100%;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.60);
}
.hourglass {
    position: relative;
    margin-top: 10%;
    margin-left: 46%;
}

.file-name {
    opacity: .8;
}

.job-status-container {
    display: flex;
    align-items: center;
    height: 33.8px;
    margin-top: -1px;
    span {
        margin-right: 5px;
    }
}


.rdo-paginator {
    margin-top: -24px;
    margin-bottom: 11px;
    display: block;
    height: 34px;
}

.search-box {
    font-family: Roboto, sans-serif, FontAwesome; 
    width: 474px; 
    margin-bottom: 10px; 
    margin-top: -7px;
}

.searchbox-container{
    position: fixed;
    z-index: 10000;
    right: 34px;
    top: 149px;
}

.ui-table-scrollable-body {
    height: 37vh;
}