.gray-background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    opacity: .5;
    background: black;
    z-index: 1000;
    top: 0;
    left: 0;
}

.popup {
    position: fixed;
    width: 600px;
    height: 420px;
    left: calc(50vw - 250px);
    top: calc(35vh - 110px);
    color: gray;
    font-size: 1.5rem;
    font-weight: normal;
    flex-direction: column;
    background: white;
    box-shadow: 1px 1px 3px 0 gray;
    padding: 15px;
    z-index: 1001;
    user-select: none;
    justify-content: space-between;
    border-radius: 5px;
}

.popup-title-section {
    display: flex;
    justify-content: space-between;
    font-size: 2rem;
    margin-bottom: 15px;
}

.popup-title {}

.popup-close {
    color: black !important;
    cursor: pointer;
}

.popup-row-titles {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.profile-name-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 82%;
}

.profile-name-edit {
    cursor: pointer;
}

.profile-name-input {
    border: none;
    box-shadow: 1px 1px 3px 0px gray;
    padding: 3px 10px;
    border-radius: 3px;
    width: 93%;
    color: black;
    outline: none;
}

.profile-name-edit:hover {
    color: black;
}

.popup-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.row-btns {
    display: flex;
    align-items: center;
    font-size: 2.3rem;
    width: 75px;
    justify-content: space-between;
    margin-right: 10px;
}

.is-default-checkbox {
    padding-left: 30px;
}

.profile-mgr-default-text {
    padding-right: 20px;
    font-weight: bold;
}

.popup-content-table-with-header {
    width: 100%;
}

.popup-content-table {
    width: calc(100% - 7px);
    overflow-y: scroll;
    max-height: 270px;
    overflow-x: hidden;
    padding-right: 7px;
}

.popup-content-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.popup-messages {
    text-align: center;
}

.table-content-div {}

.popup-trash {
    columns: black !important;
}

.popup-btns {
    display: flex;
    justify-content: flex-end;
}

.popup-btn,
.popup-btn-disabled {
    box-shadow: 1px 1px 3px 0 gray;
    padding: 7px 17px;
    color: darkslategray;
    cursor: pointer;
}

.save-btn {
    margin-right: 15px;
}

.popup-btn:hover {
    background-color: rgb(238, 238, 238);
}

.popup-btn:active {
    background-color: rgb(231, 231, 231);
}

::-webkit-scrollbar {
    width: 4px;
    overflow-y: scroll;
    background: rgb(214, 214, 214);
    box-shadow: inset 0 0 4px #707070;
    border-radius: 10px;
    margin-left: 25px;
}

::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
}

.save-spinner {
    position: absolute;
    left: calc(50vw - 12px);
    top: calc(50vh - 12px);
    z-index: 1003;
    color: lightgray;
}
