﻿#nav-primary {
	margin-bottom: 17px;

	> .nav > .dropdown > .dropdown-menu {
		width: 100%;
	}
}

.fake-tab {
	position: absolute;
	top: 0px;
	left: 0px;
	display: inline;
	background-color: #fff;
	width: 100%;
	height: 95%;
	/*z-index: 1;*/
	/*opacity: .5;*/
}

.fake-anchor {
	cursor: default;
	color: transparent;
	text-shadow: rgba(0, 0, 0, 0.3) 0px 0px 0px;
	border-top: none;
	border-left: none;
	border-right: none;

	&:hover {
		background-color: transparent;
	}
}

.gear-icon {
	height: 1em;
	width: 1em;
}