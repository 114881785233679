.downloads-container {
    height: 72vh;
}

.downloads-files {
    height: 50vh;
    overflow: hidden;
}

.cg-exports {
    min-height: 160px;
    padding-top: 20px;
    height: 23vh;
    overflow: hidden;
}
