﻿@import '../../../../styles/_palette.scss';

.cursor-pointer {
  cursor: pointer;
}

.panel-default {
  border: none;
  box-shadow: none;
  text-align: center;
}

.rdo-card-metric {
  font-size: 25px;
}

.rdo-card-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  div {
    width: calc(100% - 43px);
  }

  i {
    margin-top: 1px;
    margin-right: 7px;
  }
}

a {
  color: $font-white;
  text-decoration: underline;
}

.rdo-card-dashboard-default {
  color: $font-white;
  padding: 10px;
  position: relative;

  .panel-heading {
    padding: 0;
    margin-top: -2px;
    border: none;
    color: $font-white;

    .panel-title {
      font-size: 15px;
      font-weight: normal;
      text-transform: none;
    }
  }

  i {
    position: absolute;
    left: 10px;
    top: 9px;
  }

  div.panel-title {
    font-size: 15px;
    font-weight: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    padding: 0px 22px;
  }

  .panel-body {
    padding: 0;

    rdo-amount-difference {
      .text-danger {
        color: #c00000;
      }
      .text-success {
        color: #385723;
      }
    }
  }
}

.rdo-card-default {
  background-color: $active-tab;

  .panel-heading {
    background-color: $active-tab;
  }
}

// TODO: Remove one of these duplicate classes.
/*.rdo-card-default-blue {
	@extend .rdo-card-dashboard-default;
	@extend .rdo-card-default;
	color: #798CAB;

	.panel-heading {
		color: #798CAB;
	}
}*/

.rdo-card-default-blue {
  @extend .rdo-card-dashboard-default;
  @extend .rdo-card-default;
  background-color: var(--rdo-branding-dashboard-background-color, #5779a3);
  color: var(--rdo-branding-dashboard-font-color, #ffffff);
}

.rdo-card-default-black {
  @extend .rdo-card-dashboard-default;
  @extend .rdo-card-default;
  color: #000000;
}

/*
// TODO: Neither of these seem to be used in the app anywhere.
    Remove if that's the case.
.rdo-card-default-gold {
	@extend .rdo-card-dashboard-default;
	@extend .rdo-card-default;
	color: $primary-gold;

	.panel-heading {
		color: $primary-gold;
	}
}

.rdo-card-default-teal {
	@extend .rdo-card-dashboard-default;
	@extend .rdo-card-default;
	color: $primary-teal;

	.panel-heading {
		color: $primary-teal;
	}
}

*/

.card-set-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .flex-card {
    flex: 1 0 29%;
    margin: 0px 15px 15px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .flex-card-4 {
    @extend .flex-card;
    flex: 1 0 20%;
    padding-bottom: 12px;
  }
}
